import MicroModal from "micromodal";
import { bodyLock, bodyUnlock } from "../scripts/functions.js";

MicroModal.init({
	onShow: (modal) => callbackOpen(modal),
	onClose: (modal) => callbackClose(modal),
	openTrigger: "data-modal-open",
	closeTrigger: "data-modal-close",
	openClass: "is-open",
	disableFocus: true,
	awaitOpenAnimation: false,
	awaitCloseAnimation: true,
	debugMode: true,
});

const callbackOpen = (modal) => {
	bodyLock(300);
};
const callbackClose = (modal) => {
	let hideLock = true;

	if (document.querySelector("html").classList.contains("menu-open")) {
		hideLock = false;
	}

	bodyUnlock(300, hideLock);
};
